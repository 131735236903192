'use strict';

module.exports = function () {
    $('.input-field input:not(#birthday),.input-field textarea, .input-field label').focusin(function () {
        $(this).parent().find('label').addClass('active');
        $(this).parent().addClass('active');
    });

    $('.input-field input:not(#birthday),.input-field textarea').focusout(function () {
        if (!$(this).val()) {
            $(this).parent().find('label').removeClass('active');
            $(this).parent().removeClass('active');
        } else {
            $(this).parent().find('label').addClass('active');
            $(this).parent().removeClass('active');
        }
    });

    $('.input-field label').click(function () {
        $(this).addClass('active');
        $(this).parent().addClass('active');
        $(this).parent().find('input').focus();
    });

    $(document).on('reinit:materlialize', function() {
        console.log('reinit');
        $('.input-field input:not(#birthday),.input-field textarea, .input-field label').focusin(function () {
            $(this).parent().find('label').addClass('active');
            $(this).parent().addClass('active');
        });
    
        $('.input-field input:not(#birthday),.input-field textarea').focusout(function () {
            if (!$(this).val()) {
                $(this).parent().find('label').removeClass('active');
                $(this).parent().removeClass('active');
            } else {
                $(this).parent().find('label').addClass('active');
                $(this).parent().removeClass('active');
            }
        });
    
        $('.input-field label').click(function () {
            $(this).addClass('active');
            $(this).parent().addClass('active');
            $(this).parent().find('input').focus();
        });
    })
};
