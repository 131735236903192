/* eslint-disable new-cap */
'use strict';

var $isUserLoggedIn = $('#isUserLoggedIn').val();
if ($isUserLoggedIn === 'false') {
    if ($('.new-register-popout').length > 0) {
        var container = $('.new-register-popout-wrapper');
        var $xcluded = $('.main-banner-item a, .cta-offers, .closing-soon a, .newCart-indicator, #main-page-slider a, .quickview, .campaign-box-ui.closing-soon__item .campaign-action, .commonBanner a, .cart-indicator');
        
       
        $('.minicartId').hide();

        $xcluded.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            // var currentUrl = window.location.href;  // Get current URL
            // var newUrl = currentUrl.replace(/[^\/]+$/, 'login');  // Replace last part of URL with 'login'
            // // $('.new-register-popout').addClass('show');
            // window.location.href = newUrl;
            window.location.href = '/login';
        });

        $(document).on('livecampaign:loaded', function () {
            $('.idealz-credit-campaign-box .campaign-image .swiper-slide a, .cta-offers, .add-to-cart-area, #main-campagin-area .big-box .campaign-box-single .campaign-action, .quickview').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                // var currentUrl = window.location.href;  // Get current URL
                // var newUrl = currentUrl.replace(/[^\/]+$/, 'login');  // Replace last part of URL with 'login'
                // $('.new-register-popout').addClass('show');
                window.location.href = '/login';
            });
        });

        $('.new-register-popout').on('click', function (e) {
            if (container.parent().hasClass('show') && !container.is(e.target) && container.has(e.target).length === 0) {
                $('.new-register-popout').removeClass('show');
            }
        });
    }
} else if ($isUserLoggedIn === 'true') {
    var $xcluded1 = $('.main-banner-item a, .add-to-cart-global, .js-add-to-wish-list, .cta-offers, .closing-soon a, .newCart-indicator, #main-page-slider a, .quickview, .campaign-box-ui.closing-soon__item .campaign-action, .commonBanner a, .cart-indicator');
    $xcluded1.on('click', function (e) {
        if ($('.js-non-power-user').length > 0) {
            $('.js-non-power-user').show();
            $('.js-power-user').hide();
            e.preventDefault();
            e.stopPropagation();
        }
        if ($('.js-power-user').length > 0) {
            $('.js-power-user').show();
            $('.js-non-power-user').hide();
            e.preventDefault();
            e.stopPropagation();
        }
    });
}
