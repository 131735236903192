var isMobile = !($(window).width() >= 768);
function isWeeklyWinnerDom() {
    var $weeklyPopupDom = $('.weekly_winner_popup');
    if ($weeklyPopupDom.length > 0) {
        return false
    } else {
        return true;
    }
}

module.exports = {
    initQuantityButtons: function () {
        /**
         * Handles enabled/disabled of plus minus buttons
         * @param {jQuery} numberInput input
         */
        function assignPlusMinusClicks() {
            $('.btn-number').on('click', function (e) {
                e.preventDefault();
                var inputContainer = $(this).closest('.input-group').find('.input-number');
                inputContainer.data('prev', inputContainer.val());
                var fieldName = $(this).attr('data-field');
                var type = $(this).attr('data-type');
                var input = $("input[name='" + fieldName + "']");
                var currentVal = parseInt(input.val(), 10);
                var parent = $(this).parent();

                if (!isNaN(currentVal)) {
                    if (type === 'minus') {
                        if (currentVal > input.attr('min')) {
                            input.val(currentVal - 1).change();
                        }
                        if (currentVal === parseInt(input.attr('min'), 10)) {
                            $(this).attr('disabled');
                            $(this).closest('.item-block').find('.jsRemoveCartItem').trigger('click');
                        }
                        
                    } else if (type === 'plus') {
                        if (currentVal < input.attr('max')) {
                            input.val(currentVal + 1).change();
                            parent.find('.minus-btn').attr('disabled', false).removeClass('disabled');
                        }
                        if (parseInt(input.val(), 10) === parseInt(input.attr('max'), 10)) {
                            $(this).attr('disabled', true);
                        }
                    }
                } else {
                    input.val(0);
                }
                
                
            });
        }

        /**
         * On focus sets the current value as old value
         */
        function assignQuantityFocus() {
            $('.input-number').on('focusin', function () {
                $(this).data('oldValue', $(this).val());
            });
        }

        /**
         * Handles enabled/disabled of plus minus buttons
         * @param {jQuery} numberInput input
         */
        function handlePlusMinusButtons(numberInput) {
            var minValue = parseInt(numberInput.attr('min'), 10);
            var maxValue = parseInt(numberInput.attr('max'), 10);
            var valueCurrent = parseInt(numberInput.val(), 10);

            var name = numberInput.attr('name');

            if (valueCurrent >= minValue) {
                $(".btn-number[data-type='minus'][data-field='" + name + "']").removeAttr('disabled');
            } else {
                numberInput.val(numberInput.data('oldValue'));
            }
            if (valueCurrent <= maxValue) {
                $(".btn-number[data-type='plus'][data-field='" + name + "']").removeAttr('disabled');
            } else {
                numberInput.val(numberInput.data('oldValue'));
            }
            
            
        }

        /**
         * Changes the value of hidden quantity select
         * @param {jQuery} numberInput input
         */
        function handleHiddenQuantitySelect(numberInput) {
            var selectQunatity = numberInput.closest('.js-quantity-select').find('.quantity-select');
            selectQunatity.val(numberInput.val());
            selectQunatity.change();
        }

        /**
         * function used to format money
         * @param {*} number  amount needs to be formatted
         * @param {*} decPlaces  decimal count
         * @param {*} decSep decimal
         * @param {*} thouSep thousand
         * @returns {string} value
         */
        function customFormatMoney(number, decPlaces, decSep, thouSep) {
            decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
            decSep = typeof decSep === 'undefined' ? '.' : decSep;
            thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
            var sign = number < 0 ? '-' : '';
            var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
            var j = (j = i.length) > 3 ? j % 3 : 0;     
            
            return sign +
                (j ? i.substr(0, j) + thouSep : '') +
                i.substr(j).replace(/(\decSep)(?=(\decSep{3})+(?!\decSep))/g, "$1"+ thouSep) +
                // i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, '$1' + thouSep) +
                (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : '');
        }

        /**
         * Multiplies unit price by quantity
         * @param {jQuery} numberInput input
         */
        function multiplyUnitPrice(numberInput) {
            var quantity = numberInput.val();
            var unitPrice = Number(parseFloat($('.js-unit-price').data('unit-price'))).toFixed(2);
            var price = quantity * unitPrice;
            price = customFormatMoney(price);
            $('.js-total-price').html(price);
        }

        /**
         * Sets the quantity value
         */
        function assignQuantityChange() {
            $('.input-number').on('change', function () {
                handlePlusMinusButtons($(this));
                handleHiddenQuantitySelect($(this));
                multiplyUnitPrice($(this));
            });
        }

        /**
         * For quantity array allows only number inputs
         */
        function assignQuantityKeydown() {
            $('.input-number').on('keydown', function (e) {
                // Allow: backspace, delete, tab, escape, enter and .
                if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
                    // Allow: Ctrl+A
                    (e.keyCode === 65 && e.ctrlKey === true) ||
                    // Allow: home, end, left, right
                    (e.keyCode >= 35 && e.keyCode <= 39)) {
                    // let it happen, don't do anything
                    return;
                }
                // Ensure that it is a number and stop the keypress
                if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                    e.preventDefault();
                }
            });
        }

        assignPlusMinusClicks();
        assignQuantityFocus();
        assignQuantityChange();
        assignQuantityKeydown();
        $('body').on('live:initTimeLimited', function () {
            assignPlusMinusClicks();
        });

        $('body').on('click', '.js-close-creditAppliedInfo-box', function (e) {
            e.preventDefault();
            var $this = $(this);
            $this.closest('.creditAppliedInfo-box').removeClass('show');
        });

        $('body').on('click', '.js-info-tick-trigger', function (e) {
            e.preventDefault();
            var $this = $(this);
            $this.closest('.campaign-box, .idealz-credit-campaign-box, .product-detail-page').find('.creditAppliedInfo-box').addClass('show');
        });

        $('body').on('click', '.js-info-creditApplied', function (e) {
            e.preventDefault();
            var $this = $(this);
            var $id = $this.data('id');
            var productDetail = $('.newWrappper-campaign-Box').length;
            if (productDetail > 0) {
                $('.creditAppliedInfo-box').show();
            } else {
                $('#' + $id).show();
            }
        });

        $('body').on('click', '.js-close-creditAppliedInfo-box-desktop', function (e) {
            e.preventDefault();
            var $this = $(this);
            $this.closest('.creditAppliedInfo-box').hide();
        });

        $('body').on('click', '.deleteQty', function (e) {
            e.preventDefault();
            $(document).trigger('addToCartModule:open');
        });

        
    }
};
